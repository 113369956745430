import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Cookies from "js-cookie"
import { MenuItems } from "./MenuItems"
import styled from "styled-components"

// Zoptymalizowane i uproszczone style
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4vw;
  background-color: ${(props) =>
    !props.isScrolled ? "transparent" : "rgba(38, 38, 38, 0.85)"};
  backdrop-filter: ${(props) => (!props.isScrolled ? "none" : "blur(10px)")};
  box-shadow: ${(props) =>
    !props.isScrolled ? "none" : "0 2px 10px rgba(0, 0, 0, 0.1)"};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  height: 80px;
`

const Logo = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`

const LogoImg = styled.img`
  height: 50px;
  width: auto;
`

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 1024px) {
    display: none;
  }
`

const NavItem = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  letter-spacing: 0.5px;
  padding: 0.5rem 0;
  transition: color 0.2s ease;

  &:hover,
  &.active {
    color: #fbac03;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #fbac03;
    transition: width 0.3s ease;
  }

  &:hover::after,
  &.active::after {
    width: 100%;
  }
`

const SignInButton = styled.a`
  background-color: #fbac03;
  color: #fff;
  padding: 0.7rem 1.5rem;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(251, 172, 3, 0.3);

  &:hover {
    background-color: #e89c00;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(251, 172, 3, 0.4);
  }
`

const MobileToggle = styled.button`
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1100;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
  }
`

const ToggleLine = styled.span`
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: #fff;
  transition: all 0.3s ease;

  &:nth-child(1) {
    transform: ${({ isOpen }) =>
      isOpen ? "translateY(9px) rotate(45deg)" : "rotate(0)"};
  }

  &:nth-child(2) {
    opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    transform: ${({ isOpen }) =>
      isOpen ? "translateX(-20px)" : "translateX(0)"};
  }

  &:nth-child(3) {
    transform: ${({ isOpen }) =>
      isOpen ? "translateY(-9px) rotate(-45deg)" : "rotate(0)"};
  }
`

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(38, 38, 38, 0.95);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  z-index: 1050;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.4s ease;
  padding: 2rem;
`

const MobileNavItem = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  letter-spacing: 1px;
  transition: color 0.2s ease, transform 0.2s ease;

  &:hover,
  &.active {
    color: #fbac03;
    transform: scale(1.05);
  }
`

const MobileSignInButton = styled(SignInButton)`
  margin-top: 1rem;
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
`

const LanguageSwitcher = styled.div`
  position: relative;
  margin-left: 2rem;

  @media (max-width: 1024px) {
    display: none;
  }
`

const CurrentLanguage = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 0.4rem 1rem;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

const FlagIcon = styled.img`
  width: 22px;
  height: auto;
  border-radius: 3px;
`

const LanguageDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 1200;
  min-width: 150px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  animation: fadeIn 0.2s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const LanguageOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.7rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.2s ease;

  &:hover {
    background: #f5f5f5;
  }
`

const MobileLanguageSwitcher = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    gap: 1rem;
  }
`

const MobileLanguageOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`

const MobileLanguageOption = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover,
  &.active {
    background: rgba(251, 172, 3, 0.2);
    color: #fbac03;
  }

  &.active {
    border: 1px solid #fbac03;
  }
`

const LanguageText = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
`

function LanguageSelector() {
  const { t, i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language || "pl")
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    Cookies.set("i18next", language, { expires: 365 })
    setCurrentLanguage(language)
    setDropdownOpen(false)
  }

  useEffect(() => {
    const savedLanguage = Cookies.get("i18next")
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage)
      setCurrentLanguage(savedLanguage)
    }

    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownOpen && !event.target.closest(".language-switcher")) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [i18n, dropdownOpen])

  const getLanguageFlag = (lang) => {
    switch (lang) {
      case "pl":
        return "https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg"
      case "en":
        return "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg"
      case "cz":
        return "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg"
      default:
        return ""
    }
  }

  const getLanguageName = (lang) => {
    switch (lang) {
      case "pl":
        return "Polski"
      case "en":
        return "English"
      case "cz":
        return "Čeština"
      default:
        return ""
    }
  }

  const languages = [
    { code: "en", name: "English" },
    { code: "pl", name: "Polski" },
    { code: "cz", name: "Čeština" },
  ]

  return (
    <>
      <LanguageSwitcher className="language-switcher">
        <CurrentLanguage onClick={() => setDropdownOpen(!dropdownOpen)}>
          <FlagIcon
            src={getLanguageFlag(currentLanguage)}
            alt={currentLanguage.toUpperCase()}
          />
          {currentLanguage.toUpperCase()}
        </CurrentLanguage>
        <LanguageDropdown isOpen={dropdownOpen}>
          {languages.map((lang) => (
            <LanguageOption
              key={lang.code}
              onClick={() => changeLanguage(lang.code)}
            >
              <FlagIcon src={getLanguageFlag(lang.code)} alt={lang.name} />
              {lang.name}
            </LanguageOption>
          ))}
        </LanguageDropdown>
      </LanguageSwitcher>

      <MobileLanguageSwitcher>
        <MobileLanguageOptions>
          {languages.map((lang) => (
            <MobileLanguageOption
              key={lang.code}
              className={currentLanguage === lang.code ? "active" : ""}
              onClick={() => changeLanguage(lang.code)}
            >
              <FlagIcon src={getLanguageFlag(lang.code)} alt={lang.name} />
              <LanguageText>{lang.code.toUpperCase()}</LanguageText>
            </MobileLanguageOption>
          ))}
        </MobileLanguageOptions>
      </MobileLanguageSwitcher>
    </>
  )
}

const Navbar = () => {
  const { t } = useTranslation()
  const [isScrolled, setIsScrolled] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(null)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    // Prevent scrolling when mobile menu is open
    if (menuOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }

    return () => {
      document.body.style.overflow = "unset"
    }
  }, [menuOpen])

  const handleItemClick = (index) => {
    setActiveItem(index)
    setMenuOpen(false)
  }

  return (
    <Nav isScrolled={isScrolled}>
      <Logo href="/">
        <LogoImg src="logomagda.png" alt="Logo" />
      </Logo>

      <NavLinks>
        {MenuItems.map((item, index) => (
          <NavItem
            key={index}
            href={item.url}
            className={activeItem === index ? "active" : ""}
            onClick={() => handleItemClick(index)}
          >
            {t(item.dictKey)}
          </NavItem>
        ))}
        <SignInButton
          href="https://magda-trans.sokar.onitsoft.com/signin"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("navbar:page.rej")}
        </SignInButton>
      </NavLinks>

      <LanguageSelector />

      <MobileToggle onClick={() => setMenuOpen(!menuOpen)}>
        <ToggleLine isOpen={menuOpen} />
        <ToggleLine isOpen={menuOpen} />
        <ToggleLine isOpen={menuOpen} />
      </MobileToggle>

      <MobileMenu isOpen={menuOpen}>
        {MenuItems.map((item, index) => (
          <MobileNavItem
            key={index}
            href={item.url}
            className={activeItem === index ? "active" : ""}
            onClick={() => handleItemClick(index)}
          >
            {t(item.dictKey)}
          </MobileNavItem>
        ))}
        <MobileSignInButton
          href="https://magda-trans.sokar.onitsoft.com/signin"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("navbar:page.rej")}
        </MobileSignInButton>
        <LanguageSelector />
      </MobileMenu>
    </Nav>
  )
}

export default Navbar
