import React, { Component } from "react"
import nowe3 from "../../assets/images/nowe3.jpg"
import nowe2 from "../../assets/images/nowe2.jpg"
import nowe1 from "../../assets/images/nowe1.jpg"

const Slider = () => {
  const getProperImage = (index) => {
    switch (index) {
      case 2:
        return nowe2
      case 1:
        return nowe1
      default:
        return nowe3
    }
  }

  const [currentNumber, setCurrentNumber] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (currentNumber === 2) setCurrentNumber(0)
      setCurrentNumber((c) => c + 1)
    }, 6000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div style={{ position: "relative" }}>
      <img
        style={{ height: "100vh", width: "100%", objectFit: "cover" }}
        src={getProperImage(currentNumber)}
        alt={"slider_image"}
      />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          opacity: 0.5,
          backgroundColor: "#000",
        }}
      />
    </div>
  )
}

export default Slider
