import React from "react"
import MateCard from "./mateCard"
import {
  PageWrapper,
  Header,
  Section,
  SectionHeader,
  TeamWrapper,
} from "./teamElements"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const teamMembers = [
  {
    id: 29,
    username: "r.matracki",
    first_name: "Robert",
    last_name: "Matracki",
    email: "robert.matracki@magda-trans.pl",
    phone: "+12 350 27 23",
    is_active: true,
    is_super_user: false,
    avatar_link: "r_matracki.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Accounting", "Owner", "Manager", "Employee"],
    title: "Prokurent",
  },

  {
    id: 28,
    username: "m.matracka",
    first_name: "Magdalena",
    last_name: "Matracka",
    email: "magdalena.matracka@magda-trans.pl",
    phone: "+12 350 27 23",
    is_active: true,
    is_super_user: false,
    avatar_link: "m_matracka.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Accounting", "Owner", "Manager", "Employee"],
    title: "Prezes zarządu",
  },
  {
    id: 69,
    username: "j.trminek",
    first_name: "Jan",
    last_name: "Trminek",
    email: "jan.trminek@magda-trans.pl,",
    phone: "+420 608 607 957",
    is_active: true,
    is_super_user: false,
    avatar_link: "h_honza.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Employee"],
    title: "Key Account Manager",
  },

  {
    id: 15,
    username: "t.spyrka",
    first_name: "Tomasz",
    last_name: "Spyrka",
    email: "tomasz.spyrka@magda-trans.pl",
    phone: "721-811-211",
    is_active: true,
    is_super_user: false,
    avatar_link: "t_spyrka.png",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Manager", "Employee"],
    title: "Spedytor",
  },

  {
    id: 11,
    username: "m.karabula",
    first_name: "Magdalena",
    last_name: "Karabuła",
    email: "m.karabula@magda-trans.pl",
    phone: "668-892-152",
    is_active: true,
    is_super_user: false,
    avatar_link: "m_karabula.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  {
    id: 12,
    username: "m.liberska",
    first_name: "Marzena",
    last_name: "Liberska",
    email: "marzena.liberska@magda-trans.pl",
    phone: "668-892-171",
    is_active: true,
    is_super_user: false,
    avatar_link: "m_liberska.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },

  {
    id: 14,
    username: "t.mikulski",
    first_name: "Tomasz",
    last_name: "Mikulski",
    email: "tomasz.mikulski@magda-trans.pl",
    phone: "722-377-370",
    is_active: true,
    is_super_user: false,
    avatar_link: "t_mikulski.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },

  {
    id: 3,
    username: "d.mleczko",
    first_name: "Dawid",
    last_name: "Mleczko",
    email: "dawid.mleczko@magda-trans.pl",
    phone: "668-892-170",
    is_active: true,
    is_super_user: false,
    avatar_link: "d_mleczko.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  {
    id: 16,
    username: "w.sendor",
    first_name: "Wojciech",
    last_name: "Sendor",
    email: "wojciech.sendor@magda-trans.pl",
    phone: "781-968-810",
    is_active: true,
    is_super_user: false,
    avatar_link: "w_sendor.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },

  {
    id: 4,
    username: "j.zegarmistrz",
    first_name: "Jacek",
    last_name: "Zegarmistrz",
    email: "jacek.zegarmistrz@magda-trans.pl",
    phone: "722-377-677",
    is_active: true,
    is_super_user: false,
    avatar_link: "j_zegarmistrz.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },

  {
    id: 22,
    username: "p.kaminski",
    first_name: "Paweł",
    last_name: "Kamiński",
    email: "pawel.kaminski@magda-trans.pl",
    phone: "781-968-806",
    is_active: true,
    is_super_user: false,
    avatar_link: "p_kaminski.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  {
    id: 7,
    username: "m.wojewoda",
    first_name: "Michał",
    last_name: "Wojewoda",
    email: "michal.wojewoda@magda-trans.pl",
    phone: "781-968-807",
    is_active: true,
    is_super_user: false,
    avatar_link: "m_wojewoda.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Dispatcher", "Employee"],
    title: "Spedytor",
  },
  {
    id: 20,
    username: "b.kaszowska",
    first_name: "Bogumiła",
    last_name: "Kaszowska",
    email: "bogumila.kaszowska@magda-trans.pl",
    phone: "798-970-546",
    is_active: true,
    is_super_user: false,
    avatar_link: "b_kaszubska.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },

  // {
  //   id: 24,
  //   username: "i.skowronska",
  //   first_name: "Iza",
  //   last_name: "Skowronska",
  //   email: "iza.skowronska@magda-trans.pl",
  //   phone: "660 194 852",
  //   is_active: true,
  //   is_super_user: false,
  //   avatar_link: "i_skowronska.jpg",
  //   company_id: "cp_e82a0k",
  //   company_vat_id: "6783120981",
  //   groups: ["Broker", "Accounting", "Employee"],
  //   title: "Pro",
  // },

  {
    id: 6,
    username: "k.kocjan",
    first_name: "Kamila",
    last_name: "Kocjan",
    email: "kamila.kocjan@magda-trans.pl",
    phone: "668-892-178",
    is_active: true,
    is_super_user: false,
    avatar_link: "k_kocjan.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  {
    id: 30,
    username: "m.mikulska",
    first_name: "Magdalena",
    last_name: "Mikulska",
    email: "magdalena.mikulska@magda-trans.pl",
    phone: "+12 350 27 23",
    is_active: true,
    is_super_user: false,
    avatar_link: "m_mikulska.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Accounting", "Employee"],
    title: "Specjalista ds. administracyjnych",
  },
  {
    id: 31,
    username: "l.starynets",
    first_name: "Lyudmyla",
    last_name: "Starynets",
    email: "luda.s@magda-trans.pl",
    phone: "+12 350 27 23",
    is_active: true,
    is_super_user: false,
    avatar_link: "l_starynets.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Accounting", "Employee"],
    title: "Specjalista ds. administracyjnych",
  },
  {
    id: 26,
    username: "p.koreno",
    first_name: "Przemyslaw",
    last_name: "Koreno",
    email: "przemyslaw.koreno@magda-trans.pl",
    phone: "506-997-173",
    is_active: true,
    is_super_user: false,
    avatar_link: "p_koreno.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },

  {
    id: 10,
    username: "m.kotarba",
    first_name: "Maciej",
    last_name: "Kotarba",
    email: "maciej.kotarba@magda-trans.pl",
    phone: "668-892-172",
    is_active: true,
    is_super_user: false,
    avatar_link: "m_kotarba.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },

  {
    id: 21,
    username: "j.kaminska",
    first_name: "Jowita",
    last_name: "Kaminska",
    email: "jowita.kaminska@magda-trans.pl",
    phone: "663-064-113",
    is_active: true,
    is_super_user: false,
    avatar_link: "j_kaminska.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },

  {
    id: 8,
    username: "l.chwastek",
    first_name: "Łukasz",
    last_name: "Chwastek",
    email: "lukasz.chwastek@magda-trans.pl",
    phone: "722-377-177",
    is_active: true,
    is_super_user: false,
    avatar_link: "l_chwastek.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  // {
  //   id: 33,
  //   username: "b.zuchowicz",
  //   first_name: "Beata",
  //   last_name: "Żuchowicz",
  //   email: "beata.zuchowicz@magda-trans.pl",
  //   phone: "+12 350 27 23",
  //   is_active: true,
  //   is_super_user: false,
  //   avatar_link: "b_zuchowicz.jpg",
  //   company_id: "cp_e82a0k",
  //   company_vat_id: "6783120981",
  //   groups: ["Accounting", "Employee"],
  //   title: "Główna księgowa",
  // },
  {
    id: 2,
    username: "a.newelicz",
    first_name: "Andrzej",
    last_name: "Newelicz",
    email: "andrzej.newelicz@magda-trans.pl",
    phone: "601-757-272",
    is_active: true,
    is_super_user: false,
    avatar_link: "a_newelicz.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  {
    id: 107,
    username: "m.piejko",
    first_name: "Mikołaj",
    last_name: "Piejko",
    email: "mikolaj.piejko@magda-trans.pl",
    phone: "12 350 27 23",
    is_active: true,
    is_super_user: false,
    avatar_link: "m_piejko.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  {
    id: 34,
    username: "a.jurzec",
    first_name: "Aneta",
    last_name: "Jurzec",
    email: "aneta.jurzec@magda-trans.pl",
    phone: "+12 350 27 23",
    is_active: true,
    is_super_user: false,
    avatar_link: "a_jurzec.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Accounting", "Employee"],
    title: "Asystent księgowej",
  },
  {
    id: 115,
    username: "m.krzywda",
    first_name: "Maciej",
    last_name: "Krzywda",
    email: "maciej.krzywda@magda-trans.pl",
    phone: "532-561-765",
    is_active: true,
    is_super_user: false,
    avatar_link: "m_krzywda.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  {
    id: 116,
    username: "d.kobialka",
    first_name: "Dawid",
    last_name: "Kobialka",
    email: "dawid.kobialka@magda-trans.pl",
    phone: "532-560-675",
    is_active: true,
    is_super_user: false,
    avatar_link: "d_kobialka.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  {
    id: 19,
    username: "m.fialek",
    first_name: "Mateusz",
    last_name: "Fiałek",
    email: "mateusz.fialek@magda-trans.pl",
    phone: "660-181-769",
    is_active: true,
    is_super_user: false,
    avatar_link: "m_fialek.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
  {
    id: 1,
    username: "a.frukacz",
    first_name: "Agata",
    last_name: "Frukacz",
    email: "agata.frukacz@magda-trans.pl",
    // "phone": "668-892-155",
    is_active: true,
    is_super_user: false,
    avatar_link: "default.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },

  {
    id: 13,
    username: "m.gorecka",
    first_name: "Marta",
    last_name: "Górecka",
    email: "marta.gorecka@magda-trans.pl",
    // "phone": "660-194-852",
    is_active: true,
    is_super_user: false,
    avatar_link: "default.jpg",
    company_id: "cp_e82a0k",
    company_vat_id: "6783120981",
    groups: ["Broker", "Employee"],
    title: "Spedytor",
  },
]

// Filter team members into different categories
const zarzad = teamMembers.filter(
  (member) =>
    member.title.toLowerCase().includes("prezes zarządu") ||
    member.title.toLowerCase().includes("dyrektor") ||
    member.title.toLowerCase().includes("prokurent")
)

const spedytorzy = teamMembers.filter((member) =>
  member.title.toLowerCase().includes("spedytor")
)

const ksiegowe = teamMembers.filter(
  (member) =>
    member.title.toLowerCase().includes("główna księgowa") ||
    member.title.toLowerCase().includes("specjalista ds. administracyjnych") ||
    member.title.toLowerCase().includes("asystent księgowej")
)

// Create new Czech branch category specifically for Jan Trminek
const czechBranch = teamMembers.filter(
  (member) => member.first_name === "Jan" && member.last_name === "Trminek"
)

// Move Beata to the beginning of the accounting list if she exists
const beataIndex = ksiegowe.findIndex(
  (member) => member.first_name === "Beata" && member.last_name === "Żuchowicz"
)
if (beataIndex !== -1) {
  const beata = ksiegowe.splice(beataIndex, 1)[0]
  ksiegowe.unshift(beata)
}

const MotionDiv = ({ children, className, initial, animate, transition }) => {
  const controls = useAnimation()
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 })

  React.useEffect(() => {
    if (inView) {
      controls.start(animate)
    }
  }, [controls, animate, inView])

  return (
    <motion.div
      ref={ref}
      initial={initial}
      animate={controls}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  )
}

const Team = () => {
  return (
    <PageWrapper>
      <Header
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>Poznaj nasz zespół</h1>
      </Header>

      <Section
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <SectionHeader>Kadra zarządzająca</SectionHeader>
        <TeamWrapper className="management">
          {zarzad.map((member, index) => (
            <MateCard key={index} userInfo={member} />
          ))}
        </TeamWrapper>
      </Section>

      {/* New Czech Branch Section */}
      <Section
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <SectionHeader>Oddział Czechy</SectionHeader>
        <TeamWrapper>
          {czechBranch.map((member, index) => (
            <MateCard key={index} userInfo={member} />
          ))}
        </TeamWrapper>
      </Section>

      <Section
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <SectionHeader>Dział spedycji</SectionHeader>
        <TeamWrapper>
          {spedytorzy.map((member, index) => (
            <MateCard key={index} userInfo={member} />
          ))}
        </TeamWrapper>
      </Section>

      <Section
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <SectionHeader>Dział administracji i księgowości</SectionHeader>
        <TeamWrapper>
          {ksiegowe.map((member, index) => (
            <MateCard key={index} userInfo={member} />
          ))}
        </TeamWrapper>
      </Section>
    </PageWrapper>
  )
}

export default Team
