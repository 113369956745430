import styled from "styled-components"
import team1 from "../../assets/images/team1.jpg"

export const PageWrapper = styled.div`
  width: 100%;
  font-family: "Montserrat", Arial, sans-serif;
  background-color: #f9f9f9;
`

export const Header = styled.header`
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(${team1});
  background-size: cover;
  background-position: center;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;

  & h1 {
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`

export const Section = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 50px auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`

export const SectionHeader = styled.h2`
  font-size: 2.2em;
  color: #333;
  margin-bottom: 2rem;
  text-align: left;
  padding-left: 1rem;
  border-left: 5px solid #eab42d;
  font-weight: 600;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #eab42d;
  }
`

export const TeamWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  justify-items: center;

  &.management {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`

export const Card = styled.div`
  width: 100%;
  max-width: 300px;
  text-align: left;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  img {
    align-self: center;
    height: 280px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 5px;
  }

  h3 {
    margin: 10px 0 5px 0;
    font-size: 1.3em;
    font-weight: bold;
    color: #333;
  }

  p {
    margin: 5px 0;
    font-size: 0.95em;
    color: #666;
  }

  .title {
    color: #eab42d;
    font-weight: 600;
    margin-bottom: 10px;
  }
`

export const IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 8px 0;

  svg {
    width: 18px;
    height: 18px;
    color: #eab42d;
  }

  p {
    margin: 0;
    font-size: 0.9em;
  }
`
